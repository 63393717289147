<script>
import { Head, Link } from "@inertiajs/inertia-vue3";
import Layout from "@/Pages/Shared/Layout.vue";
import UserMenu from "@/Pages/Shared/UserMenu.vue";
import { Splide, SplideSlide, SplideTrack } from "@splidejs/vue-splide";
import "@splidejs/splide/dist/css/themes/splide-default.min.css";
import { Video } from "@splidejs/splide-extension-video";
import "@splidejs/splide-extension-video/dist/css/splide-extension-video.min.css";
import { defineComponent, onMounted, reactive, ref } from "vue";
import Loader from "@/Pages/Shared/Loader.vue";
import CategoryBox from "@/Pages/Shared/CategoryBox.vue";
import { Disclosure, DisclosureButton, DisclosurePanel } from "@headlessui/vue";

export default defineComponent({
    name: "Kirala Motor",
    components: {
        CategoryBox, Loader,
        Link,
        Head,
        Splide,
        SplideSlide,
        SplideTrack,
        Video,
        UserMenu,
        Disclosure,
        DisclosureButton,
        DisclosurePanel
    },
    computed: {
        splidedAllProducts() {
            return this.splidedArray(this.allProducts.items.data, 1);
        },
        splidedDiscounted() {
            // Take first 8 items than split them into 2
            return this.splidedArray(this.discounted.items.data.slice(0, 16), 1);
        }
    },
    data() {
        return {
            blocks: [
                {
                    title: "Motosiklet Kiralama İçin Gerekli Belgeler Nelerdir?",
                    content:
                        "                <p class=\" text-sm mt-5 font-santralregular \">Motosiklet kiralama işlemi için:</p>\n" +
                        "                <ul class=\"ml-6 list-outside text-sm mt-5 space-y-4 font-santralregular \">\n" +
                        "                    <li class=\"font-santralregular\"> Sürücü belgeniz,</li>\n" +
                        "                    <li class=\"font-santralregular\">Vergi levhanız,</li>\n" +
                        "                    <li class=\"font-santralregular\">Adli sicili kaydınız,</li>\n" +
                        "                    <li class=\"font-santralregular\">Ödeme yapacağınız kredi/banka kartı gereklidir.</li>\n" +
                        "                </ul>" +
                        "                <p class=\" text-sm mt-5 font-santralregular \">Gerekli görülmesi durumunda araç güvenliği ve geçerli mevzuatlar nedeniyle ek bilgi ve belge talebi yapılabilir, bilgi ve belge taleplerini karşılayamayan müşterilerimizin kiralama talepleri iptal edilebilmektedir.</p>\n"
                    ,
                    showBlock: true
                },
                {
                    title: "Kiralama değerlendirme aşamasında hangi ek belgeler istenebilir? İstenilen belgeleri nasıl iletmeyelim? ",
                    content:
                        "                <p class=\" text-sm mt-5 font-santralregular \">İstenen bilgi ve belgeler, e-posta kanalı ile veya panel üzerinden yüklenerek tarafımıza iletilmektedir. Bilgi ve belgeler araç/motor ve diğer ek cihazlarda değişkenlik gösterip kiralama süreniz ve bilgileriniz incelendikten sonra tarafınıza iletilecektir. İkametgâh, sürücü belgesi kopyası, gelir belgesi, ticari firma adına kiralama yapılıyorsa faaliyet belgesi/işyeri kaydı vb. evraklar talep edilebilmektedir.</p>\n",
                    showBlock: false
                },
                {
                    title: "Kira süresi içerisinde veya kira süresi bittiği zaman (araç geri teslimi sırasında) fark edilen hasar sonucunda ne olur? ",
                    content:
                        "                <p class=\" text-sm mt-5 font-santralregular \">Kiralamobil araçlarının tamamı Kiralabunu A.Ş. mülkiyetinde olup, olası kaza ve araç hasarlarında öncelikle Kiralabunu A.Ş. bilgilendirmeli ve araç tarafınıza bildirilecek olan yetkili servise tarafınızca teslim edilmelidir. Yetkili servis incelemesi akabinde, araç hasarları belirlenerek ilgili hasarların % 100’ü müşteri tarafından karşılanmaktadır. </p>\n",
                    showBlock: false
                },
                { title: "	Kiraladığım aracı nasıl teslim alacağım? 	", content: " <p class=' text-sm mt-5 font-santralregular '>	Kiralama yaptığınız aracı adresinize en yakın yetkili bayiden teslim alabilirsiniz, teslim alacağınız adres ve yetkili servis/bayi tarafınıza bildirilecektir. Aracı teslim aldığınız bayiye veya tarafınıza bildirilecek bayi/depo adresine teslim etmeniz gerekmektedir. 	</p>", showBlock: false }
            ],
            readMoreBlocks: [],
            selectedClass: "shadow-inner shadow-sm border-2 h-14 md:h-14 ts:h-16 rounded-full border-kbgreen p-2 lg:p-4 items-center justify-center flex bg-white !text-black text-xs hover:!scale-100",
            hiddenbtn1: true,
            isOpen: false,
            products: [
                {
                    selectedPrice: 6945,
                    selectedMonth: 24,
                    name: "Honda Dio 110 Scooter",
                    brand: "honda",
                    explanation: "84 km/h, 6.4 kW, 9 Nm",
                    price: { 12: 10685, 18: 8175, 24: 6945 },
                    features: {
                        "Motor Tipi": "eSP hava soğutmalı, 2 zamanlı SOHC",
                        "Motor Hacmi (cc)": "109 cc",
                        "Maksimum güç": "6,4 kW, 7.500 d/d",
                        "Maksimum tork": "9 Nm, 5.500 d/d",
                        "Uzunluk x Genişlik x Yükseklik (mm)": "1,925 x 686 x 1,125",
                        "Sele yüksekliği (mm)": "785",
                        "Dingil mesafesi (mm)": "1.280",
                        "Boş ağırlık (kg)": "100",
                        "Frenler (Ön / Arka)": "320 mm hidrolik disk, 130 mm kapmana",
                        "Lastikler (Ön / Arka)": "80/90 R14",
                        "Ön Süspansiyon": "31 mm teleskopik ön çatal",
                        "Arka Süspansiyon": "Tekli amortisör",
                        "Dahili Bagaj": "Evet",
                        "Kombine Fren Sistemi": "Evet",
                        "Idle Stop": "Evet"
                    },
                    images: ["honda-dio-1.webp", "honda-dio-1.png"]
                },
                {
                    selectedPrice: 7335,
                    selectedMonth: 24,
                    name: "Honda Activa 125 Scooter",
                    brand: "honda",
                    explanation: "100 km/h, 9.2 kW, 11.6 Nm",
                    price: { 12: 11310, 18: 8640, 24: 7335 },
                    features: {
                        "Motor Tipi": "Hava soğutmalı, 4 Zamanlı, SOHC, Tek Silindirli",
                        "Enjeksiyon Sistemi": "PGM-FI",
                        "Şanzıman Tipi": "Otomatik Vites (CVT)",
                        "Vites Sayısı": "Sonsuz Oran",
                        "Motor Hacmi (cc)": "124",
                        "Maksimum Güç HP (kW) @ d/d": "8,3 HP (6,11 kW) @ 6,250 d/d",
                        "Maksimum Tork (Nm @ d/d)": "10,4 Nm @ 5,000 d/d",
                        "Yakıt Tüketimi": "1,8L",
                        "Yakıt Kapasitesi (L)": "5,3L",
                        "Uzunluk (mm)": "1850",
                        "Genişlik (mm) ": "707",
                        "Yükseklik (mm)": "1170",
                        "Dingil Mesafesi (mm)": "1260",
                        "Sele Yüksekliği (mm)": "712",
                        "Yerden Yükseklik (mm)": "162",
                        "Islak Ağırlık (kg)": "110",
                        "Ön Süspansiyon": "Teleskopik Çatallar",
                        "Arka Süspansiyon": "3 Seviye Ayarlı Hidrolik",
                        "Fren Sistemi": "Kombine Fren Sistemi",
                        "Ön Fren": "190 mm Disk",
                        "Arka Fren": "130 mm Kampana",
                        "Ön Lastik": "90/90-12 54J",
                        "Arka Lastik": "90/100-10 53J",
                        "Dahili Bagaj": "Evet",
                        "Gündüz Farları": "Evet",
                        "LCD Gösterge Paneli": "Evet",
                        "CVT Şanzıman": "Evet"
                    },
                    images: ["honda-activia-125-1.webp", "honda-activia-125-1.png"]
                },
                {
                    selectedPrice: 7335,
                    selectedMonth: 24,
                    name: "Honda CB125F Motosiklet",
                    brand: "honda",
                    explanation: "115 km/h, 8 kW, 10,9 Nm",
                    price: { 12: 11310, 18: 8640, 24: 7335 },
                    features: {
                        "Motor tipi": "4 zamanlı, 2 supaplı OHC tek silindir",
                        "Motor hacmi": "124 cc",
                        "Maksimum güç": "10,87 hp (8 kW) @ 7,500 d/d",
                        "Maksimum tork": "10,9 Nm @ 6,000 d/d",
                        "CO2 emisyonu": "1.4 l/100 km / 34 g/km",
                        "Uzunluk x Genișlik x Yükseklik (mm)": "2.020 x 751 x 1.103",
                        "Sele yüksekliği (mm)": "790",
                        "Dingil mesafesi (mm)": "1,28",
                        "Boș ağırlık (kg)": "116 kg",
                        "Frenler (Ön / Arka)": "Tek piston kaliperli 240 mm tek disk / 130 mm kampana",
                        "Lastikler (Ön / Arka)": "80/100 – 18 M/C 47P / 90/90 – 18 M/C 51P",
                        "Ön Süspansiyon": "30 mm teleskopik",
                        "Arka Süspansiyon": "Çift amortisör, 5 kademeli yük ayarı"
                    },
                    images: ["honda-cb125f-1.webp", "honda-cb125f-1.png"]
                },
                {
                    selectedPrice: 9750,
                    selectedMonth: 24,
                    name: "Honda PCX 125 Scooter",
                    brand: "honda",
                    explanation: "100 km/h, 9.2 kW, 11.6 Nm",
                    price: { 12: 15185, 18: 11530, 24: 9750 },
                    features: {
                        "Motor tipi": "eSP+su soğutmalı, SOHC, tek silindir",
                        "Motor hacmi": "125 cc",
                        "Maksimum güç": "9,2 kW, 8.750 d/d",
                        "Maksimum tork": "11,8 Nm, 6.500 d/d",
                        "Uzunluk x Genişlik x Yükseklik (mm)": "1,935 x 740 x 1,105",
                        "Sele yüksekliği (mm)": "764",
                        "Dingil mesafesi (mm)": "1.315",
                        "Boş ağırlık (kg)": "130",
                        "Frenler (Ön / Arka)": "2 piston kaliperli 220 mm hidrolik disk /",
                        "Lastikler (Ön / Arka)": "110/70 R 14 / 130/70 R13",
                        "Ön Süspansiyon": "31 mm teleskopik ön çatal",
                        "Arka Süspansiyon": "Tekli amortisör, Pro-Link salıncak"
                    },
                    images: ["honda-pcx-1.webp", "honda-pcx-1.png"]
                },
                {
                    selectedPrice: 10140,
                    selectedMonth: 24,
                    name: "Yamaha NMAX 125 Scooter",
                    brand: "yamaha",
                    explanation: "110 km/h, 9 kW, 11,2 Nm",
                    price: { 12: 15805, 18: 11995, 24: 10140 },
                    features: {
                        "Motor tipi": "4-zamanlı, Sıvı-soğutmalı, SOHC, 4-subap",
                        "Motor hacmi": "125 cc",
                        "Çap x Strok": "52,0 × 58,7 mm",
                        "Sıkıştırma oranı": "11.2:1",
                        "Maksimum güç": "9,0 kW (12,2 PS)/8000 dev/dak",
                        "Maksimum tork": "11,2 N.m (1,1 kgf.m)/6000 dev/dak",
                        "Yağlama": "Islak karter",
                        "Yakıt sistemi": "Yakıt Enjeksiyon",
                        "Ateşleme sistemi": "TCI",
                        "Çalıştırma": "Elektrik",
                        "Şanzıman": "V-Kayışı Otomatik",
                        "Yakıt tüketimi": "2,2L/100km",
                        "CO2 emission": "52g/km",
                        "Ön süspansiyon": "Teleskopik çatal",
                        "Ön teker hareketi": "100 mm",
                        "Arka süspansiyon": "Tek kol",
                        "Arka teker hareketi": "85 mm",
                        "Ön fren": "Hidrolik tek disk fren",
                        "Arka fren": "Hidrolik tek disk fren",
                        "Ön lastik": "110/70-13M/C 48P Tubeless",
                        "Arka lastik": "130/70-13M/C 63P Tubeless",
                        "Toplam uzunluk": "1.935 mm",
                        "Toplam genişlik": "740 mm",
                        "Toplam yükseklik": "1.160 mm",
                        "Sele yüksekliği": "765 mm",
                        "Tekerleklerarası mesafe": "1.340 mm",
                        "Minimum yerden yükseklik": "125 mm",
                        "Islak ağırlık": "131 kg",
                        "Yakıt tankı kapasitesi": "7,1 L	"
                    },
                    images: ["yamaha-nmax-250-1.webp", "yamaha-nmax-250-1.png"]
                },
                {
                    selectedPrice: 16305,
                    selectedMonth: 24,
                    name: "Yamaha XMAX 250 Scooter",
                    brand: "yamaha",
                    explanation: "135 km/h, 16.8 kW, 24,3 Nm",
                    price: { 12: 25690, 18: 19375, 24: 16305 },
                    features: {
                        "Motor tipi": "4-zamanlı, Sıvı-soğutmalı, SOHC, 4-subap, Tek silindir",
                        "Motor hacmi": "249 cc",
                        "Çap x Strok": "70,0 mm x 64,9 mm",
                        "Sıkıştırma oranı": "10.5:1",
                        "Maksimum güç": "7.000 dev/dak'da 16,8 kW",
                        "Maksimum tork": "5.500 dev/dak'da 24,3 Nm",
                        "Yağlama": "Islak karter",
                        "Yakıt sistemi": "Yakıt Enjeksiyon",
                        "Ateşleme sistemi": "TCI",
                        "Çalıştırma": "Elektrik",
                        "Şanzıman": "V-Kayışı Otomatik",
                        "Yakıt tüketimi": "3,1 L / 100 km",
                        "CO2 emission": "73 g/km",
                        "Ön süspansiyon": "Teleskopik çatal",
                        "Ön teker hareketi": "110 mm",
                        "Arka süspansiyon": "Tek kol",
                        "Arka teker hareketi": "79 mm",
                        "Ön fren": "Hidrolik tek disk, Ø267 mm (ABS)",
                        "Arka fren": "Hidrolik tek disk, Ø245 mm (ABS)",
                        "Ön lastik": "120/70-15",
                        "Arka lastik": "140/70-14",
                        "Toplam uzunluk": "2180 mm",
                        "Toplam genişlik": "795 mm",
                        "Toplam yükseklik": "1410 mm - 1460 mm (ayarlanabilir ön cam)",
                        "Sele yüksekliği": "795 mm",
                        "Tekerleklerarası mesafe": "1540 mm",
                        "Minimum yerden yükseklik": "135 mm",
                        "Islak ağırlık": "183 kg",
                        "Yakıt tankı kapasitesi": "13,2 L"
                    },
                    images: ["yamaha-xmax-250-1.webp", "yamaha-xmax-250-1.png"]
                },
                {
                    selectedPrice: 4000,
                    selectedMonth: 24,
                    name: "Honda EM1-e Scooter",
                    brand: "honda",
                    explanation: "45 km/h, 1.7 kW, 90 Nm",
                    price: { 12: 5000, 18: 4500, 24: 4000 },
                    features: {
                        "Frenler (Ön/Arka)": "Disk / Kampana",
                        "Lastikler (Ön/Arka)": "90/90-12 / 100/90-10",
                        "Süspansiyon (Ön)": "Teleskopik",
                        "Süspansiyon (Arka)": "Çift",
                        "Motor Tipi": "Teker içi 3 fazlı motor, Fırçasız",
                        "Maksimum Tork": "90 Nm",
                        "Maksimum Güç": "1,7 kW",
                        "Mod bazında menzil": "48 km – Eco Modu, 30 km – Standart Mod",
                        "Enerji tüketimi (Wsa/km)": "47 Wsa/km",
                        "Tırmanma Açısı": "12°@RW 55kg / 10°@RW 75kg",
                        "Ağırlık": "10.2 kg",
                        "Menzil": "41 km",
                        "Şarj süresi (25%-75%)": "160 dk",
                        "Şarj süresi": "6 saat",
                        "Yaşam döngüsü": "2.500+",
                        "Tip": "Lityum iyon",
                        "Voltaj": "50,3 V",
                        "Dingil Mesafesi (mm)": "1300 mm",
                        "Sele Yüksekliği (mm)": "740 mm",
                        "Yerden Yükseklik (mm)": "135 mm",
                        "UxGxY (mm)": "1860 mm x 680 mm x 1080",
                        "Islak Ağırlık (kg)": "(Batarya dahil) 95kg",
                        "Dönüş Çapı": "1,9 m",
                        "LCD Gösterge Paneli": "Var",
                        "USB Type A": "Var",
                        "LED Ön/Arka Far": "Var",
                        "Standart/Eco Sürüş Modları": "Var",
                        "İmobilizer": "Var"
                    },
                    images: ["honda-em1-scooter-1.webp", "honda-em1-scooter-1.png"]
                }

            ]
        };
    },

    methods: {
        splidedArray(arr, size) {
            return arr.reduce((acc, e, i) => (i % size ? acc[acc.length - 1].push(e) : acc.push([e]), acc), []);
        },
        toogleBlock(block) {
            block.showBlock = !block.showBlock;
        },
        selectMonth(month, item) {
            item.selectedMonth = month;
            item.selectedPrice = item.price[month];
        },
        string_to_slug(str) {
            str = str.replace(/^\s+|\s+$/g, ""); // trim
            str = str.toLowerCase();

            // remove accents, swap ñ for n, etc
            var from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
            var to = "aaaaeeeeiiiioooouuuunc------";
            for (var i = 0, l = from.length; i < l; i++) {
                str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
            }

            str = str.replace(/[^a-z0-9 -]/g, "") // remove invalid chars
                .replace(/\s+/g, "-") // collapse whitespace and replace by -
                .replace(/-+/g, "-"); // collapse dashes

            return str;
        }
    },
    props: {
        discounted: Object
    },
    setup() {
        const main = ref("<InstanceType<typeof Splide>>");
        const thumbs = ref("<InstanceType<typeof Splide>>");
        const videos = ["gG5sWlLQyIY", "gRXb2rCKjdk", "4JlAq0RDVRk", "1BzZB-vhCz8", "2O5-0sBhhKw"];

        const mainOptions = {
            type: "loop",
            perPage: 1,
            perMove: 1,
            gap: "1rem",
            pagination: true,
            arrows: false,
            rewind: true,
            autoPlay: true
        };

        const thumbsOptions = {
            type: "slide",
            rewind: true,
            gap: "1rem",
            pagination: false,
            fixedWidth: 110,
            fixedHeight: 70,
            cover: true,
            focus: "center",
            isNavigation: true,
            updateOnMove: true,
            arrows: false
        };

        onMounted(() => {
            const thumbsSplide = thumbs.value?.splide;
            if (thumbsSplide) {
                main.value?.sync(thumbsSplide);
            }
        });

        return {
            main,
            thumbs,
            thumbsOptions,
            mainOptions,
            videos,
            extensions: { Video }
        };
    },
    layout: Layout

});
</script>

<template>
    <Head title="Kirala Motor" />

    <div class="">
        <section class=" max-w-kbmobile md:max-w-tablet ts:max-w-ts 2xl:max-w-7xl mt-4 mx-auto">
            <div class="mb-6 flex flex-row">
                <div class="w-full lg:pt-2">
                    <div class="sliderclass">
                        <splide :options="{ rewind: true, gap: '1rem', perPage: 1, arrows: false }" class="block mts:hidden ts:hidden mt-4">
                            <splide-slide>
                                <picture>
                                    <source srcset="../../images/kiralamotor/kiralamotor-banner-1.webp" type="image/webp">
                                    <source srcset="../../images/kiralamotor/kiralamotor-banner-1.jpeg" type="image/jpeg">
                                    <img class="w-full" src="../../images/kiralamotor/kiralamotor-banner-1.jpeg" />
                                </picture>
                            </splide-slide>

                        </splide>

                        <splide :options="{ rewind: true, gap: '1rem', perPage: 1, arrows: false }" class="hidden mts:block ts:hidden">
                            <splide-slide>
                                <picture>
                                    <source srcset="../../images/kiralamotor/kiralamotor-banner-1.webp" type="image/webp">
                                    <source srcset="../../images/kiralamotor/kiralamotor-banner-1.jpeg" type="image/jpeg">
                                    <img class="w-full" src="../../images/kiralamotor/kiralamotor-banner-1.jpeg" />
                                </picture>
                            </splide-slide>

                        </splide>

                        <splide :options="{ rewind: true, gap: '1rem', perPage: 1, arrows: false }" class="hidden mts:hidden ts:block">
                            <splide-slide>
                                <picture>
                                    <source srcset="../../images/kiralamotor/kiralamotor-banner-1.webp" type="image/webp">
                                    <source srcset="../../images/kiralamotor/kiralamotor-banner-1.jpeg" type="image/jpeg">
                                    <img class="w-full" src="../../images/kiralamotor/kiralamotor-banner-1.jpeg" />
                                </picture>
                            </splide-slide>
                        </splide>
                    </div>
                    <h2 class="text-2xl md:text-4xl font-santralextrabold text-kbblue text-center my-6">Yola çıkmanın en hızlı ve kolay yolu!</h2>

                </div>
            </div>
        </section>
        <section class=" bg-kb-mid-grey py-10">
            <div class=" max-w-kbmobile md:max-w-tablet ts:max-w-ts 2xl:max-w-7xl mx-auto">
                <div class="flex flex-wrap justify-center items-center">
                    <!--                    <div class="w-full lg:w-1/3 my-4 px-2 xl:px-3">-->
                    <!--                        <div class="bg-white rounded-xl p-4 md:p-3 flex flex-wrap items-center min-h-[220px] xl:min-h-[175px]">-->
                    <!--                            <div class="flex justify-center rounded-full bg-white w-full md:w-1/4">-->
                    <!--                                <img class="mb-4 w-1/3 md:w-full xl:w-2/3 md:pr-2 mx-auto" src="../../images/kiralamotor/kiralamotor-i1.svg" />-->
                    <!--                            </div>-->
                    <!--                            <div class="w-full md:w-3/4">-->
                    <!--                                <h2 class="text-lg md:text-xl font-semibold text-kbgreen text-center">Bakım Masrafları Bizden</h2>-->
                    <!--                                <p class="text-sm text-black font-santralregular text-center leading-tight">-->
                    <!--                                    Tüm rutin bakım ve onarımlar bizim-->
                    <!--                                    tarafımızdan düzenli olarak yapılır. Siz-->
                    <!--                                    sadece işinize odaklanın.-->
                    <!--                                </p>-->
                    <!--                            </div>-->
                    <!--                        </div>-->
                    <!--                    </div>-->
                    <div class="w-full lg:w-1/2 my-4 px-2 xl:px-3">
                        <div class="bg-white rounded-xl p-4 md:p-3 flex flex-wrap items-center min-h-[220px] xl:min-h-[175px]">
                            <div class="flex justify-center rounded-full bg-white w-full md:w-1/4">
                                <img class="mb-4 w-1/3 md:w-full xl:w-2/3 md:pr-2 mx-auto" src="../../images/kiralamotor/kiralamotor-i2.svg" />
                            </div>
                            <div class="w-full md:w-3/4">
                                <h2 class="text-lg md:text-xl font-semibold text-kbgreen text-center">Sigorta ve MTV Dahil</h2>
                                <p class="text-sm text-black font-santralregular text-center leading-tight">
                                    Zorunlu trafik sigortası ve motorlu taşıtlar vergisi ödemeleri tarafımızdan karşılanır. Ekstra masraf yok!
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="w-full lg:w-1/2 my-4 px-2 xl:px-3">
                        <div class="bg-white rounded-xl p-4 md:p-3 flex flex-wrap items-center min-h-[220px] xl:min-h-[175px]">
                            <div class="flex justify-center rounded-full bg-white w-full md:w-1/4">
                                <img class="mb-4 w-1/3 md:w-full xl:w-2/3 md:pr-2 mx-auto" src="../../images/kiralamotor/kiralamotor-i3.png" />
                            </div>
                            <div class="w-full md:w-3/4">
                                <h2 class="text-lg md:text-xl font-semibold text-kbgreen text-center">Kira Dönemi Sonunda
                                    Sahip Olma Fırsatı</h2>
                                <p class="text-sm text-black font-santralregular text-center leading-tight">
                                    Kira süresi sonunda avantajlı
                                    fiyatlarla motoru satın alabilirsiniz.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="w-full lg:w-1/2 my-4 px-2 xl:px-3">
                        <div class="bg-white rounded-xl p-4 md:p-3 flex flex-wrap items-center min-h-[220px] xl:min-h-[175px]">
                            <div class="flex justify-center rounded-full bg-white w-full md:w-1/4">
                                <img class="mb-4 w-1/3 md:w-full xl:w-2/3 md:pr-2 mx-auto" src="../../images/kiralamotor/kiralamotor-i4.svg" />
                            </div>
                            <div class="w-full md:w-3/4">
                                <h2 class="text-lg md:text-xl font-semibold text-kbgreen text-center">Vergi Avantajları</h2>
                                <p class="text-sm text-black font-santralregular text-center leading-tight">
                                    Kiralama bedelindeki KDV’yi tamamen
                                    düşebilirsiniz. Ayrıca geri kalan tutarları
                                    da gider olarak gelir vergisinden
                                    düşebilirsiniz. KDV ve gelir vergisi
                                    ödemelerinizi minimuma indirin.
                                </p>
                            </div>
                        </div>
                    </div>
                    <!--                    <div class="w-full lg:w-1/3 my-4 px-2 xl:px-3">-->
                    <!--                        <div class="bg-white rounded-xl p-4 md:p-3 flex flex-wrap items-center min-h-[220px] xl:min-h-[175px]">-->
                    <!--                            <div class="flex justify-center rounded-full bg-white w-full md:w-1/4">-->
                    <!--                                <img class="mb-4 w-1/3 md:w-full xl:w-2/3 md:pr-2 mx-auto" src="../../images/kiralamotor/kiralamotor-i5.svg" />-->
                    <!--                            </div>-->
                    <!--                            <div class="w-full md:w-3/4">-->
                    <!--                                <h2 class="text-lg md:text-xl font-semibold text-kbgreen text-center">Mevsime Uygun Lastikler</h2>-->
                    <!--                                <p class="text-sm text-black font-santralregular text-center leading-tight">-->
                    <!--                                    Kışlık lastik ihtiyacınız olduğunda,-->
                    <!--                                    talep ettiğiniz an değişim yapılır.-->
                    <!--                                    Güvenliğiniz bizim için önemli.-->
                    <!--                                </p>-->
                    <!--                            </div>-->
                    <!--                        </div>-->
                    <!--                    </div>-->
                    <div class="w-full lg:w-1/2 my-4 px-2 xl:px-3">
                        <div class="bg-white rounded-xl p-4 md:p-3 flex flex-wrap items-center min-h-[220px] xl:min-h-[175px]">
                            <div class="flex justify-center rounded-full bg-white w-full md:w-1/4">
                                <img class="mb-4 w-1/3 md:w-full xl:w-2/3 md:pr-2 mx-auto" src="../../images/kiralamotor/kiralamotor-i6.svg" />
                            </div>
                            <div class="w-full md:w-3/4">
                                <h2 class="text-lg md:text-xl font-semibold text-kbgreen text-center">0 KM Yeni Motorlar</h2>
                                <p class="text-sm text-black font-santralregular text-center leading-tight">
                                    Koruma ve çanta demirleri takılı,
                                    kullanıma hazır sıfır kilometre
                                    araçlar sizi bekliyor.
                                </p>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </section>
        <section class="mt-6 md:mt-4 py-1 md:py-9 ">
            <div class="flex mx-auto flex-col max-w-kbmobile md:max-w-tablet ts:max-w-ts 2xl:max-w-7xl">
                <div class="flex w-full justify-center">
                    <div class="text-2xl md:text-3xl my-2 lg:my-0 mx-0 lg:mx-4 self-center text-center w-full md:w-auto text-kbblue">Motorlarımız</div>
                </div>
                <div class="pl-0 lg:pl-0 lg:p-0 lg:mt-9">
                    <div class="md:w-2/3 mx-auto">
                        <div class="my-4 rounded-lg p-6 border border-3 rounded-lg flex-1" v-for="item in products">
                            <div class="flex flex-wrap items-center mt-2">
                                <div class="w-full lg:w-2/5">
                                    <Link :href="`/kiralamotor/${string_to_slug(item.name)}`">
                                        <picture>
                                            <source :srcset="`/images/kiralamotor/${item.images[0]}`" type="image/webp">
                                            <source :srcset="`/images/kiralamotor/${item.images[1]}`" type="image/png">
                                            <img :src="`/images/kiralamotor/${item.images[1]}`">
                                        </picture>
                                    </Link>
                                </div>
                                <div class="md:pl-4 w-full lg:w-3/5">
                                    <div class="text-2xl font-semibold mt-3">
                                        <Link :href="`/kiralamotor/${string_to_slug(item.name)}`" v-html="item.name"></Link>
                                    </div>
                                    <div class="text-sm mt-1 text-kbgray">
                                        <Link :href="`/kiralamotor/${string_to_slug(item.name)}`" v-html="item.explanation"></Link>
                                    </div>
                                    <div class="mt-3 max-w-smv-p-box lg:max-w-v-p-box">
                                        <div class="flex text-xs text-center cursor-pointer">
                                            <ul class="flex border rounded-full bg-white w-full items-center justify-center shadow-xl h-8 ts:h-12">
                                                <li v-for="(value,key) in item.price" :key="key" class="text-textgray whitespace-nowrap w-16 h-14 text-xs hover:scale-125 transform duration-300 ease-in-out items-center justify-center flex" :class="[ item.selectedMonth == key ? selectedClass : '' ]" @click="selectMonth(key, item)" v-html="key+' Ay'"></li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="flex mt-2">
                                        <span class="font-semibold text-2xl mt-2"><Link :href="`/kiralamotor/${string_to_slug(item.name)}`">{{ item.selectedPrice }} TL /</Link></span>
                                        <span class="text-xs self-center ml-2 font-santralregular"><Link :href="`/kiralamotor/${string_to_slug(item.name)}`">Aylık ödenecek tutar</Link></span>
                                    </div>
                                    <button class="bg-kbgreen text-white text-sm lg:text-base py-2 w-[200px] rounded-full mt-2 hover:opacity-[90%] whitespace-nowrap leading-tight font-santralextrabold flex justify-center">
                                        <Link :href="`/kiralamotor/${string_to_slug(item.name)}`"> Görüntüle</Link>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="mt-12 bg-white py-10 bg-[#f8f8f8]">
            <div class="max-w-kbmobile md:max-w-tablet ts:max-w-ts 2xl:max-w-7xl mx-auto">
                <div class="flex flex-wrap justify-center items-center">
                    <div class="w-full lg:w-1/2">
                        <picture>
                            <source srcset="../../images/kiralamotor/kiralamotor-g-1.webp" type="image/webp">
                            <source srcset="../../images/kiralamotor/kiralamotor-g-1.jpg" type="image/jpg">
                            <img class="w-full md:pr-8" src="../../images/kiralamotor/kiralamotor-g-1.jpg" />
                        </picture>
                    </div>
                    <div class="w-full lg:w-1/2 xl:pr-12 pb-6 lg:pb-0 ">
                        <h2 class="text-2xl md:text-3xl font-semibold mb-3 lg:mb-6 text-kbgreen mt-6 md:mt-0">Bizimle iletişime geçin</h2>
                        <div class="flex items-center mb-5">
                            <div class="bg-[#f3fbf6] rounded-full mr-4 lg:mr-6"><a href="tel:+9008502551552" class="flex justify-center items-center w-10 h-10">
                                <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 26.343 25.838">
                                    <path id="phone-handset-solid" d="M11.175,15.076A14.55,14.55,0,0,0,16.461,18.5l2.693-2.143a.479.479,0,0,1,.543,0l5,3.221a1.429,1.429,0,0,1,.236,2.271L22.59,24.161A2.857,2.857,0,0,1,20,24.926,25.064,25.064,0,0,1,7.747,18.5,24.136,24.136,0,0,1,1.125,6.476,2.7,2.7,0,0,1,1.911,3.94L4.34,1.6a1.429,1.429,0,0,1,2.229.229L9.9,6.761a.45.45,0,0,1,0,.536L7.7,9.933a14.107,14.107,0,0,0,3.471,5.143Z" transform="translate(-0.049 -0.163)" fill="none" stroke="#231f20"
                                          stroke-width="2"></path>
                                </svg>
                            </a></div>
                            <div><a class="text-lg block font-bold" href="tel:+908502551552">0850 255 1552</a></div>
                        </div>
                        <div class="flex items-center mb-5">
                            <div class="bg-[#f3fbf6] rounded-full mr-4 lg:mr-6"><a href="mailto:destek@kiralabunu.com" class="flex justify-center items-center w-10 h-10">
                                <svg width="23" height="18" viewBox="0 0 26.325 20.711">
                                    <g id="mail-outline" transform="translate(1 1)">
                                        <path id="Path_2940" data-name="Path 2940" d="M5.714,6.75H25.361A2.339,2.339,0,0,1,27.7,9.089V23.123a2.339,2.339,0,0,1-2.339,2.339H5.714a2.339,2.339,0,0,1-2.339-2.339V9.089A2.339,2.339,0,0,1,5.714,6.75Z" transform="translate(-3.375 -6.75)" fill="none" stroke="#231f20" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"></path>
                                        <path id="Path_2941" data-name="Path 2941" d="M7.875,11.25,16.3,17.8l8.42-6.549" transform="translate(-4.133 -7.508)" fill="none" stroke="#231f20" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"></path>
                                    </g>
                                </svg>
                            </a></div>
                            <div><a class="text-lg block font-bold" href="mailto:destek@kiralabunu.com">destek@kiralabunu.com</a></div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <div class=" flex justify-center items-center flex-col w-full mb-12 mt-10">
            <div class="w-full max-w-kbmobile md:max-w-tablet ts:max-w-ts 2xl:max-w-7xl mx-auto flex flex-col justify-center items-center mb-10">
                <div class="text-3xl md:text-4xl my-2 lg:my-0 mx-0 lg:mx-4 self-center text-center w-full md:w-auto text-kbblue">Bilgilendirme</div>

                <div class="mt-6 flex flex-col justify-center items-center w-full">
                    <div
                        :class="[block.showBlock ? ' py-4 rounded-xl mb-4 w-full px-4' : 'flex justify-between items-center w-full py-4 px-4 rounded-full mb-4']"
                        v-for="(block, index) in blocks" :key="index">
                        <div class="flex justify-between w-full" :class="[!block.showBlock ? '' : '']">
                            <div>
                                <p @click="toogleBlock(block)"
                                   class="text-base font-santralextrabold ts:text-xl text-left cursor-pointer">
                                    {{ block.title }}</p>
                            </div>
                            <div class="rounded-full w-8 h-8 bg-white "
                                 :class="[block.showBlock ? ' rounded-full ' : ' ']"
                                 @click="toogleBlock(block)">
                                <button type="button" class="flex justify-center items-center w-8 h-8"
                                        v-if="!block.showBlock">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="23.872" height="21.712"
                                         viewBox="0 0 23.872 21.712">
                                        <g id="Group_119" data-name="Group 119" transform="translate(0)">
                                            <path id="Path_19" data-name="Path 19"
                                                  d="M1.6,21.712A1.528,1.528,0,0,1,0,20.261V1.451A1.528,1.528,0,0,1,1.6,0a1.528,1.528,0,0,1,1.6,1.451v18.81A1.528,1.528,0,0,1,1.6,21.712Z"
                                                  transform="translate(10.341 0)" fill="#000000" />
                                            <path id="Path_20" data-name="Path 20"
                                                  d="M1.451,23.872A1.528,1.528,0,0,1,0,22.277V1.6A1.528,1.528,0,0,1,1.451,0,1.528,1.528,0,0,1,2.9,1.6V22.277A1.528,1.528,0,0,1,1.451,23.872Z"
                                                  transform="translate(23.872 9.405) rotate(90)" fill="#000000" />
                                        </g>
                                    </svg>
                                </button>
                                <button type="button" class="flex justify-center items-center w-8 h-8"
                                        v-if="block.showBlock">
                                    <svg id="Group_119" data-name="Group 119" xmlns="http://www.w3.org/2000/svg"
                                         width="22.332"
                                         height="2.985" viewBox="0 0 22.332 2.985">
                                        <path id="Path_19" data-name="Path 19"
                                              d="M1.492,21.712A1.472,1.472,0,0,1,0,20.261V1.451A1.472,1.472,0,0,1,1.492,0,1.472,1.472,0,0,1,2.985,1.451v18.81A1.472,1.472,0,0,1,1.492,21.712Z"
                                              transform="translate(22.022) rotate(90)" fill="#000" />
                                        <path id="Path_20" data-name="Path 20"
                                              d="M1.451,22.332A1.472,1.472,0,0,1,0,20.84V1.492A1.472,1.472,0,0,1,1.451,0,1.472,1.472,0,0,1,2.9,1.492V20.84A1.472,1.472,0,0,1,1.451,22.332Z"
                                              transform="translate(22.332 0.042) rotate(90)" fill="#000" />
                                    </svg>
                                </button>
                            </div>
                        </div>
                        <div class="mr-5" v-html="block.content" v-if="block.showBlock"></div>
                    </div>
                    <div
                        :class="[block.showBlock ? ' py-4 rounded-xl mb-4 w-full px-4' : 'flex justify-between items-center w-full py-4 px-4 rounded-full mb-4']"
                        v-for="(block, index) in readMoreBlocks" :key="index">
                        <div class="flex justify-between w-full" :class="[!block.showBlock ? '' : '']">
                            <div>
                                <p @click="toogleBlock(block)"
                                   class="text-base font-santralextrabold ts:text-xl text-left cursor-pointer">
                                    {{ block.title }}</p>
                            </div>
                            <div class="rounded-full w-8 h-8 bg-white "
                                 :class="[block.showBlock ? ' rounded-full ' : ' ']"
                                 @click="toogleBlock(block)">
                                <button type="button" class="flex justify-center items-center w-8 h-8"
                                        v-if="!block.showBlock">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="23.872" height="21.712"
                                         viewBox="0 0 23.872 21.712">
                                        <g id="Group_119" data-name="Group 119" transform="translate(0)">
                                            <path id="Path_19" data-name="Path 19"
                                                  d="M1.6,21.712A1.528,1.528,0,0,1,0,20.261V1.451A1.528,1.528,0,0,1,1.6,0a1.528,1.528,0,0,1,1.6,1.451v18.81A1.528,1.528,0,0,1,1.6,21.712Z"
                                                  transform="translate(10.341 0)" fill="#000000" />
                                            <path id="Path_20" data-name="Path 20"
                                                  d="M1.451,23.872A1.528,1.528,0,0,1,0,22.277V1.6A1.528,1.528,0,0,1,1.451,0,1.528,1.528,0,0,1,2.9,1.6V22.277A1.528,1.528,0,0,1,1.451,23.872Z"
                                                  transform="translate(23.872 9.405) rotate(90)" fill="#000000" />
                                        </g>
                                    </svg>
                                </button>
                                <button type="button" class="flex justify-center items-center w-8 h-8"
                                        v-if="block.showBlock">
                                    <svg id="Group_119" data-name="Group 119" xmlns="http://www.w3.org/2000/svg"
                                         width="22.332"
                                         height="2.985" viewBox="0 0 22.332 2.985">
                                        <path id="Path_19" data-name="Path 19"
                                              d="M1.492,21.712A1.472,1.472,0,0,1,0,20.261V1.451A1.472,1.472,0,0,1,1.492,0,1.472,1.472,0,0,1,2.985,1.451v18.81A1.472,1.472,0,0,1,1.492,21.712Z"
                                              transform="translate(22.022) rotate(90)" fill="#000" />
                                        <path id="Path_20" data-name="Path 20"
                                              d="M1.451,22.332A1.472,1.472,0,0,1,0,20.84V1.492A1.472,1.472,0,0,1,1.451,0,1.472,1.472,0,0,1,2.9,1.492V20.84A1.472,1.472,0,0,1,1.451,22.332Z"
                                              transform="translate(22.332 0.042) rotate(90)" fill="#000" />
                                    </svg>
                                </button>
                            </div>
                        </div>
                        <div class="mr-5" v-html="block.content" v-if="block.showBlock"></div>
                    </div>

                    <a href="/kiralamotor-bilgilendirme"
                       class="bg-black font-santralextrabold text-base lg:text-lg text-white rounded-full py-1.5 px-4 self-center font-bold hover:bg-kbgreen">
                        Daha Fazla
                    </a>
                </div>
            </div>
        </div>

    </div>
</template>
